import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import Logo from "../images/logo.inline.svg"

const Credits = styled.div`
  width: 100%;
  font-size: 0.65rem;
  padding-top: 2rem;
  position: absolute;
  z-index: 1000;
  font-family: "Conv_AvenirLTStd-Book";
  color: #fff;
  bottom: 4rem;
  text-align: center;

  @media only screen and (min-width: 1024px) {
  }

  a {
    text-decoration: none;
    color: #fff;
    transition: 0.5s all ease-in-out;
  }
`

const Splash = styled.div`
  width: 100vw;
  min-height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    z-index: 10;
  }

  .video-wrap {
    z-index: -1 !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    transform: scale(1.2);
    filter: grayscale(1);
    transition: 0.3s all ease-in-out;

    &.loaded {
      opacity: 0.5;
      transform: scale(1);
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .choose {
    font-family: "glamour_absolute_extendedRg";
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.2rem;
    position: fixed;
    top: 4rem;
    display: block;
    color: white;
    z-index: 10;

    @media only screen and (min-width: 640px) {
      font-size: 2rem;
    }
    /* font-family: "Conv_AvenirLTStd-Book";
    font-size: 0.8rem;
    position: fixed;
    top: 2rem;
    display: block;
    color: white;
    z-index: 10; */
  }

  .content {
    position: relative;
    z-index: 20;
    max-width: 880px;
    width: 100%;
    color: white;
    text-align: center;
    padding: 0 2rem;
    opacity: 0;
    transform: scale(1.2);
    transition: 0.3s all ease-in-out;

    @media only screen and (min-width: 527px) {
      padding: 0 4rem;
    }

    &.loaded {
      opacity: 1;
      transform: scale(1);
    }
    .logo {
      width: 100%;
      margin-bottom: 1rem;
    }

    .text {
      display: flex;
      justify-content: center;
    }

    .cities {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      flex-wrap: wrap;

      @media only screen and (max-width: 650px) {
        flex-wrap: wrap;
      }
      /*
      .coming-soon span {
        font-size: 1rem;
        width: 100%;
        font-family: "glamour_absolute_extendedRg";
        position: absolute;
        bottom: -1rem;
      } */

      .link-wrap {
        text-align: center;

        &:nth-child(1) {
          margin-bottom: 0.5rem;
        }

        span {
          /* font-size: 14px; */
          margin-left: 4px;
        }

        @media only screen and (max-width: 650px) {
          width: 100%;
          margin-bottom: 1rem;

          &:nth-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .fake-anchor,
    a {
      font-family: "Conv_AvenirLTStd-Book";
      color: white;
      text-decoration: none;
      font-size: 1.5rem;
      position: relative;
      transition: 0.5s color ease-in-out 0.3s;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0.5rem 0 0.5rem 1rem;

      @media only screen and (min-width: 500px) {
        font-size: 2rem;
      }

      &:before {
        width: 10px;
        height: calc(100% - 16px);
        /* height:calc(100% - 5px); */
        position: absolute;
        content: "";
        top: 5px;
        left: 0;
        z-index: -1;
        transition: 0.3s height ease-in-out 0s, 0.3s transform ease-in-out 0s,
          0.5s width ease-in-out 0.3s;
      }

      span {
        z-index: 10;
        position: relative;
        display: block;
      }

      &.leeds {
        &:before {
          background: #fac900;
        }
      }
      &.york {
        &:before {
          background: #da3649;
        }
      }
      &.yorkshire {
        &:before {
          background: #2bab8d;
        }
      }
    }

    a {
      &:hover:before {
        height: calc(100% + 4px);
        transform: translateY(-10px);
        width: calc(100% + 1rem);
      }

      &:hover {
        color: black;
      }
    }
    h1 {
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-family: "Conv_AvenirLTStd-Book";
      font-size: 1.2rem;
      margin-bottom: 3rem;

      .hide {
        width: 0;
        height: 0;
        text-indent: -9999999px;
        position: relative;
        display: block;
      }
    }
  }
`

export default function IndexPage() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const content = document.querySelector(".content")
      const video = document.querySelector(".video-wrap")
      content.classList.add("loaded")
      video.classList.add("loaded")
    }
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <Splash>
        <div className="content">
          <Logo className="logo" />
          <div className="text">
            <h1>
              <span>
                <span className="hide">Independent Life</span>Click a city to
                explore each half of Yorkshire
              </span>
            </h1>
          </div>
          <div className="cities">
            <div className="link-wrap">
              <a className="leeds" href="https://leeds.independentlife.co.uk">
                Leeds + Yorkshire Areas
              </a>
            </div>
            <div className="link-wrap">
              <a className="york" href="https://york.independentlife.co.uk">
                York + Yorkshire Areas
              </a>
            </div>
          </div>
        </div>

        <div className="video-wrap">
          <video
            controls={false}
            autoPlay={true}
            muted={true}
            playsinline={true}
            loop={true}
          >
            <source src="https://player.vimeo.com/external/543584477.hd.mp4?s=ead123b8a0edf8b219cbf2273ffef75947de39a3&profile_id=175"></source>
          </video>
        </div>

        <div className="choose">
          <strong>- Discover Yorkshire With Us -</strong>
        </div>

        <Credits>&copy; Independent City Ltd.</Credits>
      </Splash>
    </Layout>
  )
}
